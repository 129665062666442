* {
  font-family: "Titillium Web", sans-serif;
  font-size: 18px;
}
.FolderTree {
  font-family: "Titillium Web", sans-serif;
  font-size: 18px;
}

.FolderIcon {
  background-image: url("../img/folder.svg") !important;
  fill: transparent;
}

.FolderOpenIcon {
  background-image: url("../img/folderOpen.svg") !important;
  fill: transparent;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.list-inline-item .fa {
  width: 25px;
  height: 25px;
  background: #358dca;
  text-align: center;
  color: white;
  line-height: 26px;
  border-radius: 13px;
  margin-right: 5px;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1;
  font-size: 14px;
  line-height: 1;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.section strong {
  font-weight: bolder;
}

.section {
  text-decoration: none;
  color: #358dca;
}

.section:hover {
  text-decoration: underline;
  color: #8a1e04;
}

.mt-10 {
  margin-top: 35px;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.double-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 2;
}

.image-footer {
  float: right;
}

.btn-small {
  text-decoration: none;
  color: #358dca;
}
